import {v4} from 'uuid';
import cx from 'classnames';
import PropTypes from 'prop-types';
import {toast} from 'react-toastify';
import {Typography} from './Typography';
import {PB_CloseLine as PBCloseLine} from '~/common/svg/PB_CloseLine';

export const toastAlert = (message, options, inToastButtonConfig = {}) => {
  const {icon, type, title, className, toastCallback = null} = options || {};
  let toastFunc = type ? toast[type] : toast;
  if (!toastFunc) {
    evite.log('Error - bad type passed to toastAlert');
    toastFunc = toast;
  }
  const toastId = options.toastId || `toast_${v4()}`;

  return toastFunc(
    <Toast
      type={type}
      icon={icon}
      message={message}
      className={className}
      title={title}
      onDismiss={() => {
        if (toastCallback) toastCallback();
        toast.dismiss(toastId);
      }}
      buttonText={inToastButtonConfig?.text}
      onButtonClick={inToastButtonConfig?.onClick}
      id={options.id}
    />,
    {
      ...options,
      toastId,
      icon: false,
    }
  );
};

export const Toast = ({
  id,
  className,
  icon,
  type,
  message,
  title,
  buttonText,
  onButtonClick = null,
  onDismiss = null,
}) => (
  <div className={cx('toast', type, className)} id={id}>
    {icon}
    {title ? (
      <div className="toast__content">
        <Typography variant="paragraph2" className="toast__title">
          {title}
        </Typography>
        <Typography variant="paragraph2" className="toast__message">
          {message}
        </Typography>
      </div>
    ) : (
      <Typography variant="paragraph2" className="toast__message">
        {message}
      </Typography>
    )}
    {buttonText && (
      <button aria-label="action" type="button" onClick={onButtonClick}>
        {buttonText}
      </button>
    )}
    <button
      aria-label="Close Message"
      type="button"
      className="unstyled-button"
      onClick={onDismiss}
    >
      <PBCloseLine ratio={0.75} />
    </button>
  </div>
);

Toast.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.node,
  type: PropTypes.oneOf(['success', 'information', 'warning', 'error']),
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  onButtonClick: PropTypes.func,
  onDismiss: PropTypes.func,
};
